import React, { useState, useEffect } from "react";
import SelectedOption from "../component/selected_options";
import Search from "../component/search";

import Equipment_table from "../component/equipment_table";
import Equipment_SecondType from "../component/equipment_secondtype";
import Pagination from "../component/Pagination";
import axios from "axios";
import Model from "../component/Model";

function Productlisting() {
  const [equipment_list, setEquipmentList] = useState([]);
  const [model, Setmodel] = useState("");
  // const [manufacturerActive, SetmanufacturerActive] = useState("");
  const [manufacturers, setManufacturers] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noresult, setNoResult] = useState("");
  const [search_value, setsearch] = useState("");
  const [searchFilled, setSearchFilled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState();
  const [height, setheight] = useState();
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  // const [selectedModel, setSelectedModel] = useState("");





  const fetchManufacturers = async () => {
    try {
      const response = await axios.get("jad_pe_Manufacturer_listing_EquipementPage.php");
      setManufacturers(response.data);
      fetchModelList();
    }
    catch (error) {
      console.error("Error fetching manufacturers:", error.message);
    }

  };


  const fetchModelList = async () => {
    try {
      const response = await axios.get("jad_pe_ModelList_EquipementPage.php");
      setModelList(response.data);
    } catch (error) {
      console.error("Error fetching model list:", error.message);
    }
  };


  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await axios.get('/jad_pe_equipment_listings.php', {
        params: {
          keyword: search_value,
          manufacturer: selectedManufacturer,
          model: model,
          page: currentPage,
          limit: 10
        }
      });

      const responseData = response.data;

      if (responseData.error) {
        console.error("Error fetching data:", responseData.error);
      } else {
        setLoading(false);
        setEquipmentList(responseData.message);
        setTotalProducts(responseData.TotalProducts);
        setNoResult(" ");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlePageChange = async (newPage) => {
    setCurrentPage(newPage);
    try {
      window.scrollTo({ top: 0, behavior: "smooth" });
      window.parent.postMessage({ messageType: "scrolltop", data: 0 }, "*");
    } catch (error) {
      console.error("Error handling page change:", error.message);
    }
  };

  useEffect(() => {
    fetchManufacturers();
  }, []);


  const clearFilter = () => {
    searchfunction("");
    Setmodel("");
    setSelectedManufacturer("");
    setCurrentPage(1);
  };

  const sendIframeHeightToParent = () => {
    const iframeHeight = document.body.scrollHeight;
    const windowWidth = window.innerWidth;
    if (windowWidth > 500) {

      //  console.log("Sending iframe height:", iframeHeight);
      window.parent.postMessage(
        { messageType: "iframeHeight", data: iframeHeight },
        "*"
      );
    }
    else {

      let totalHeight = (windowWidth * 1.704) * equipment_list.length
      //  console.log("Sending iframe height:", totalHeight);
      window.parent.postMessage(
        { messageType: "iframeHeight", data: totalHeight },
        "*"
      );
    }


    setheight(iframeHeight);
  };

  useEffect(() => {
    sendIframeHeightToParent();
    // console.log("ari")
  }, [totalProducts]);

  useEffect(() => {
    fetchData();
  }, [search_value, model, selectedManufacturer, currentPage]);





  const searchfunction = (query) => {
    const formattedQuery = query.replace(/\s+/g, '%20');
    setsearch(formattedQuery);
    setCurrentPage(1);
  };

  const handleModelChange = (event) => {
    const formattedModel = event.replace(/\s+/g, '%20');
    Setmodel(formattedModel);
    setCurrentPage(1);
  };


  // const Setmenufecture_fun = (event) => {
  //   setSelectedManufacturer(event);
  //   setCurrentPage(1);
  // };

  const getInitialVisual = () => {
    return window.innerWidth < 900 ? "first" : "second";
  };

  const [activeVisual, setActiveVisual] = useState(getInitialVisual());



  const handleWindowResize = () => {
    setActiveVisual(getInitialVisual());
  };
  const handleVisualClick = (visual) => {
    setActiveVisual(visual);
  };
  const tableHeaders =
    activeVisual === "second" ? (
      <div className="table-header">
        <div className="equipment table-head">
          <h5>Equipment</h5>
        </div>
        <div className="table-head-2 table-head">
          <h5>Model</h5>
        </div>
        <div className="table-head-2 table-head">
          <h5>Vintage</h5>
        </div>
        <div className="table-head-2 table-head">
          <h5>Inspection Available</h5>
        </div>
        <div className="table-head-2 table-head">
          <h5>Condition</h5>
        </div>
        <div className="table-head-3 table-head">
          <h5>Manufacturer</h5>
        </div>
        <div className="table-head-3 table-head">
          <h5>Actions</h5>
        </div>
      </div>
    ) : (
      <div className="table-header"></div>
    );
  const defaultImageOne =
    "https://jadgroup.goaspendigital.com/wp-content/uploads/2024/01/Grou-768x433.png";
  const defaultImageTwo =
    "https://jadgroup.goaspendigital.com/wp-content/uploads/2024/01/Group-1000001810-768x422.png";

  const equipment_data_html =
    equipment_list &&
      Array.isArray(equipment_list) &&
      equipment_list.length > 0 ? (
      equipment_list.map((value, index) => (
        <div className="Not_flex" key={index}>
          <Equipment_table
            data={value}
            jad_id={value["JAD_ID"]}
            description={value.Description}
            model={value.Model}
            vintage={value.Vintage}
            Inspection_Available={value.Is_Inspection_Available}
            Condition={value.Condition}
            Manufacturer={value.Manufacturer}
            File={value.File}
            thumb={
              value?.Pictures?.length > 0
                ? value.Pictures[0]?.["Picture 1"] || defaultImageOne
                : defaultImageOne
            }
            loading={value.Pictures.length > 0 ? true : false}
          />
        </div>
      ))
    ) : (
      <div className="no-result">{noresult}</div>
    );

  const equipment_data_html_two =
    equipment_list &&
      Array.isArray(equipment_list) &&
      equipment_list.length > 0 ? (
      equipment_list.map((value, index) => (
        <div key={index}>
          <Equipment_SecondType
            data={value}
            jad_id={value["JAD_ID"]}
            description={value.Description}
            model={value.Model}
            vintage={value.Vintage}
            Inspection_Available={value.Is_Inspection_Available}
            Condition={value.Condition}
            Manufacturer={value.Manfacturer_Id}
            thumb={
              value?.Pictures?.length > 0
                ? value.Pictures[0]?.["Picture 1"] || defaultImageTwo
                : defaultImageTwo
            }
          />
        </div>
      ))
    ) : (
      <div style={{ textAlign: 'center' }} className="no-result">{noresult}</div>
    );

  const handleManufacturerChange = (manufacturer) => {
    // Replace spaces with a specific character (e.g., hyphen)
    const formattedManufacturer = manufacturer.replace(/\s+/g, '%20'); // Replace spaces with hyphens
    setSelectedManufacturer(formattedManufacturer);
    setCurrentPage(1);
  };


  const equipmentData =
    activeVisual === "second" ? equipment_data_html : equipment_data_html_two;
  const withPagination = totalProducts > 10 && (
    <Pagination
      onPageChange={handlePageChange}
      currentPage={currentPage}
      total={totalProducts}
    />
  );
  return (
    <>
      <div className="banner-image">
        <h1>Equipment Available for Sale</h1>
      </div>
      <div className="filter-header-container">
        <div className="filter-container">
          <SelectedOption
            name="Manufacturer"
            values={manufacturers["Manufacturer"]}
            searchfunction={handleManufacturerChange}
            searchvalue={selectedManufacturer}
            type="single"
          />
          <SelectedOption
            name="Model"
            values={modelList["models"]}
            searchfunction={handleModelChange}
            searchvalue={model}
            type="single"
          />

          <button
            className={`Desctop visual-option ${activeVisual === "second" ? "active-visual" : ""
              }`}
            onClick={() => handleVisualClick("second")}
          >
            {activeVisual === "second" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="34"
                viewBox="0 0 33 34"
                fill="none"
              >
                <g clip-path="url(#clip0_993_5178)">
                  <line
                    x1="0.00146484"
                    y1="12.1526"
                    x2="31.6722"
                    y2="12.1526"
                    stroke="#017CC2"
                    stroke-width="2.58536"
                  />
                  <line
                    x1="10.3418"
                    y1="33.4817"
                    x2="10.3418"
                    y2="1.81094"
                    stroke="#017CC2"
                    stroke-width="2.58536"
                  />
                  <line
                    x1="0.00146484"
                    y1="1.81102"
                    x2="31.6722"
                    y2="1.81102"
                    stroke="#017CC2"
                    stroke-width="2.58536"
                  />
                  <line
                    x1="0.00146484"
                    y1="22.494"
                    x2="31.6722"
                    y2="22.494"
                    stroke="#017CC2"
                    stroke-width="2.58536"
                  />
                  <line
                    x1="-1.29199"
                    y1="32.8354"
                    x2="30.3787"
                    y2="32.8354"
                    stroke="#017CC2"
                    stroke-width="2.58536"
                  />
                </g>
                <rect
                  x="1.29268"
                  y="1.81099"
                  width="30.3781"
                  height="30.3781"
                  rx="5.17072"
                  stroke="#017CC2"
                  stroke-width="2.58536"
                />
                <defs>
                  <clipPath id="clip0_993_5178">
                    <rect
                      y="0.518311"
                      width="32.9634"
                      height="32.9634"
                      rx="6.46341"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="34"
                viewBox="0 0 33 34"
                fill="none"
              >
                <g opacity="0.32">
                  <g clip-path="url(#clip0_993_7585)">
                    <line
                      x1="0.00146484"
                      y1="12.1526"
                      x2="31.6722"
                      y2="12.1526"
                      stroke="#333333"
                      stroke-width="2.58536"
                    />
                    <line
                      x1="10.3418"
                      y1="33.4817"
                      x2="10.3418"
                      y2="1.81094"
                      stroke="#333333"
                      stroke-width="2.58536"
                    />
                    <line
                      x1="0.00146484"
                      y1="1.81108"
                      x2="31.6722"
                      y2="1.81108"
                      stroke="#333333"
                      stroke-width="2.58536"
                    />
                    <line
                      x1="0.00146484"
                      y1="22.4939"
                      x2="31.6722"
                      y2="22.4939"
                      stroke="#333333"
                      stroke-width="2.58536"
                    />
                    <line
                      x1="-1.29199"
                      y1="32.8355"
                      x2="30.3787"
                      y2="32.8355"
                      stroke="#333333"
                      stroke-width="2.58536"
                    />
                  </g>
                  <rect
                    x="1.29268"
                    y="1.81099"
                    width="30.3781"
                    height="30.3781"
                    rx="5.17072"
                    stroke="#333333"
                    stroke-width="2.58536"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_993_7585">
                    <rect
                      y="0.518311"
                      width="32.9634"
                      height="32.9634"
                      rx="6.46341"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            )}
          </button>

          <button
            className={`Desctop visual-option ${activeVisual === "first" ? "active-visual" : ""
              }`}
            onClick={() => handleVisualClick("first")}
          >
            {activeVisual === "first" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
              >
                <g clip-path="url(#clip0_993_7591)">
                  <rect
                    x="3.54907"
                    y="2.45752"
                    width="11.6341"
                    height="11.6342"
                    rx="1.93902"
                    stroke="#017CC2"
                    stroke-width="2.58536"
                  />
                  <rect
                    x="20.3545"
                    y="2.45752"
                    width="11.6341"
                    height="11.6342"
                    rx="1.93902"
                    stroke="#017CC2"
                    stroke-width="2.58536"
                  />
                  <rect
                    x="3.54907"
                    y="19.9084"
                    width="11.6341"
                    height="11.6342"
                    rx="1.93902"
                    stroke="#017CC2"
                    stroke-width="2.58536"
                  />
                  <rect
                    x="20.3545"
                    y="19.9084"
                    width="11.6341"
                    height="11.6342"
                    rx="1.93902"
                    stroke="#017CC2"
                    stroke-width="2.58536"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_993_7591">
                    <rect
                      width="32.9634"
                      height="32.9634"
                      fill="white"
                      transform="translate(0.963379 0.518311)"
                    />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
              >
                <g clip-path="url(#clip0_993_5184)">
                  <rect
                    x="3.54907"
                    y="2.4574"
                    width="11.6341"
                    height="11.6342"
                    rx="1.93902"
                    stroke="#A7A7A7"
                    stroke-width="2.58536"
                  />
                  <rect
                    x="20.3545"
                    y="2.4574"
                    width="11.6341"
                    height="11.6342"
                    rx="1.93902"
                    stroke="#A7A7A7"
                    stroke-width="2.58536"
                  />
                  <rect
                    x="3.54907"
                    y="19.9086"
                    width="11.6341"
                    height="11.6342"
                    rx="1.93902"
                    stroke="#A7A7A7"
                    stroke-width="2.58536"
                  />
                  <rect
                    x="20.3545"
                    y="19.9086"
                    width="11.6341"
                    height="11.6342"
                    rx="1.93902"
                    stroke="#A7A7A7"
                    stroke-width="2.58536"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_993_5184">
                    <rect
                      width="32.9634"
                      height="32.9634"
                      fill="white"
                      transform="translate(0.963379 0.518311)"
                    />
                  </clipPath>
                </defs>
              </svg>
            )}
          </button>

          <button
            onClick={() => clearFilter()}
            className={
              search_value == "" && model == "" && selectedManufacturer == ""
                ? "clear-all-btn d-none"
                : "clear-all-btn"
            }
          >
            {searchFilled ? "Clear Search" : "Clear All filter"}
          </button>
          <div className="showing-results">
            {totalProducts > 0 ? (
              totalProducts > 10 ? (
                <h5>
                  Showing{" "}
                  {currentPage === 1
                    ? `1-${Math.min(10, totalProducts)}`
                    : `${(currentPage - 1) * 10 + 1}-${Math.min(
                      currentPage * 10,
                      totalProducts
                    )}`}{" "}
                  of {totalProducts}
                </h5>
              ) : (
                <h5>
                  Showing 1-{totalProducts}{" "}
                  {totalProducts === 1 ? "product" : "products"}
                </h5>
              )
            ) : null}
          </div>

          <Search
            value={search_value}
            searchfunction={(query) => searchfunction(query)}
            setsearchfill={() => setSearchFilled(true)}
            setsearchempty={() => setSearchFilled(false)}
          />
        </div>
      </div>


      {loading && (
        <div
          className="loading-bar-container"
          style={{ height: height + "px" }}
        >
          <div className="loading-bar">
            <div className="spinner"></div>
          </div>
        </div>
      )}
      <div className="Display_box">
        {tableHeaders}
        {equipmentData}
      </div>
      {withPagination}
    </>
  );
}

export default Productlisting;
