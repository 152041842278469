import React from "react";
import { useNavigate } from "react-router-dom";
import '../component/css/MachineItem.css';
import '../App.css'

const Machineitem2 = ({
  id,
  imageUrl,
  topNumber1,
  manufacture,
  model,
  statuses,
  serviceTickets,
  cmaStartDate,
  cmaEndingDate,
  cmaBalance,
}) => {
  const navigate = useNavigate();

  const handleArrowClick = () => {
    if (id) {
      sessionStorage.setItem('machineId', id);
      navigate(`/CAMdetails/${id}`);
    } else {
      console.error("ID is undefined or null");
    }
  };





  return (
    <div className="Data_image_Box">
      <div className="Image-Box">
        <img style={{ marginLeft: '5px', width: '90%' }} src={imageUrl} className="Product_img" alt="Machine" />
        <div className="Top_numbers">
          <p>{topNumber1}</p>
        </div>
        <div>
          <p className="status">{statuses}</p>
        </div>
      </div>
      <div className="Image_details">
        <img
          src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/arrow.png"
          className="Arrow_for_next"
          alt="Arrow"
          onClick={handleArrowClick}
        />
        <div className="together_container">
          <div className="Div_1">
            <div className="T-this">
              <p className="heading-MachineItem2">Manufacture</p>
              <span className="Manufacture-Name">{manufacture}</span>
            </div>
            <div className="T-this">
              <p className="heading-MachineItem2">Model</p>
              <span className="Manufacture-Name">{model}</span>
            </div>
            <div style={{ textAlign: 'left' }} className="T-this">
              <p className="heading-MachineItem2">Service Tickets</p>
              <span className="Manufacture-Name">{serviceTickets}</span>
            </div>
            <div className="T-this">
              <p className="heading-MachineItem2">CMA Start Date</p>
              <span className="Manufacture-Name">{cmaStartDate}</span>
            </div>
            <div className="T-this">
              <p className="heading-MachineItem2">CMA Ending Date</p>
              <span className="Manufacture-Name">{cmaEndingDate}</span>
            </div>
          </div>
          <div className="dividerline">
            <h3 className="CMA_Balance">CMA Balance</h3>
            <div className="Div_2">
              <div className="T-this">
                <p className="para">Parts Cap Remaining</p>
                <span>{cmaBalance.partsCapRemaining}</span>
              </div>
              <div className="T-this">
                <p className="para">Calibration Remaining</p>
                <span>{cmaBalance.calibrationRemaining}</span>
              </div>
              <div className="T-this">
                <p className="para">Support Hours</p>
                <span>{cmaBalance.supportHours}</span>
              </div>
              <div className="T-this">
                <p className="para">On Site service visit</p>
                <span>{cmaBalance.onSiteServiceVisit}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Machineitem2;
