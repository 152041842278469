import React, { useState, useEffect, useRef } from "react";
import '../component/css/Search.css';

function Search(props) {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef(null);


  const performSearch = (query) => {
    if (query.length >= 4) {
      setLoading(true);
      props.searchfunction(query);
      props.setsearchfill();
      setLoading(false);
    } else {
      props.setsearchempty();
    }
  };


  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);


    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }


    timeoutRef.current = setTimeout(() => {
      performSearch(value.trim());
    }, 1000);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      performSearch(inputValue.trim());
    }
  };

  useEffect(() => {
    if (props.value === "") {
      setInputValue("");
    }
  }, [props.value]);

  return (
    <>
      <div className="search-container">
        <input
          type="text"
          className="search-input"
          placeholder="Part Number, JAD-ID, Model..."
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <button className="search-btn" onClick={() => performSearch(inputValue.trim())}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M9.04289 15.3333C12.7248 15.3333 15.7096 12.3486 15.7096 8.66667C15.7096 4.98477 12.7248 2 9.04289 2C5.36099 2 2.37622 4.98477 2.37622 8.66667C2.37622 12.3486 5.36099 15.3333 9.04289 15.3333Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.3762 17L13.7512 13.375"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {loading && (
          <div className="loading-bar">
            <div className="spinner"></div>
          </div>
        )}
      </div>
    </>
  );
}

export default Search;
