import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../appstates";
import '../component/css/Second_header.css'

const Secondheader = () => {
  const { state, dispatch } = useAppState();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    console.log(state.ActiveMenu);
  }, [state.ActiveMenu]);

  const updateActivemenu = (value, link) => {
    dispatch({ type: 'SET_ACTIVE_NAV', payload: value });
    navigate(link);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    document.querySelector('.Hamburger').classList.toggle('open');
  };

  return (
    <div className="Secondheader">
      <div className="In_logo">
        <img
          src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Logo.png"
          alt="logo2.0"
          className="Logo_img"
        />
      </div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>


      <button className="Hamburger" onClick={toggleMobileMenu}>
        <span></span>
        <span></span>
        <span></span>
      </button>

      <div   >
        <div className={`Menu_text ${isMobileMenuOpen ? "open" : ""}`}>
          <div className="taptap1">
            <button
              className={`Menu ${state.ActiveMenu === "Home" ? "MenuActive" : ""}`}
              onClick={() => updateActivemenu("Home", "/user-Machines")}
            >
              <img src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Home.png" alt="Home" />
              <p>Home</p>
            </button>
            <button
              className={`Menu ${state.ActiveMenu === "CMA Machines" ? "MenuActive" : ""}`}
              onClick={() => updateActivemenu("CMA Machines", "/CMAMachines")}
            >
              <img src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Cma.png" alt="CMA Machines" />
              <p>CMA Machines</p>
            </button>
            <button
              className={`Menu ${state.ActiveMenu === "Service Request" ? "MenuActive" : ""}`}
              onClick={() => updateActivemenu("Service Request", "/ServiceRequest")}
            >
              <img src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/servics.png" alt="Service Request" />
              <p>Service Request</p>
            </button>
          </div>
          <div className="taptap2">
            <button
              className={`Conacts ${state.ActiveMenu === "Help Guide" ? "MenuActive" : ""}`}
              onClick={() => {
                updateActivemenu("Help Guide");
                window.open("https://docs.interconnecta.com/jadgrp-cma-0311frm", "_blank");
              }}
            >
              <img src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/help.png" alt="Help Guide" />
              <p>Help Guide</p>
            </button>

            <button
              className={`Conacts ${state.ActiveMenu === "Profile" ? "MenuActive" : ""}`}
              onClick={() => updateActivemenu("Profile", "/Profilepersonal")}
            >
              <img src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/profile.png" alt="Profile" />
              <p>Profile</p>
            </button>
            <div className="Conacts">
              <img src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/bell.png" alt="Bell" />
            </div>
          </div>

        </div>

      </div>



    </div>

  );
};

export default Secondheader;
