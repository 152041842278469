import React from 'react'

function ErrorPage() {
    return (
        <div style={{ textAlign: 'justify' }}>
            <h1>404 Page Error</h1>
            <p>Note: Please Enter Valid Route</p>
        </div>
    )
}

export default ErrorPage