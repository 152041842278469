import React, { useState } from "react";
import { Link } from "react-router-dom";
import './css/ServiceRequestItem3.css';

const ServiceRequestItem3 = ({
  srNo,
  description,
  machineName,
  type,
  status,
  Time,
  Name,
  Webpage,
  Recordid
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <table className="T-thiss">
      <tbody>
        <tr>

          <td className="Dark">
            <Link to={`/ServiceNotesStatus/${Recordid}`}>{srNo}</Link>
          </td>
          <td className="description">{description}</td>
          <td className="machinename">{machineName}</td>
          <td className="type">{type}</td>
          <td className="status-status">{status}</td>
          <td className="Time">{Time}</td>
          <td className="requestName">{Name}</td>
          <td className="requestWebpage">{Webpage}</td>
          <td className="expand-arrow" onClick={toggleExpand}>
            <span className="arrow-icon">{isExpanded ? "▲" : "▼"}</span>
          </td>
        </tr>
        {isExpanded && (
          <tr className="expanded-row">
            <td colSpan="9">
              <div className="expanded-content">
                <p ><strong>Status:</strong> <p style={{ background: '#D9EEF5', color: '#0C6AA7', padding: '3px', borderRadius: '8px', display: 'inline' }}>{status}</p></p>
                <p > <strong>Time:</strong> {Time}</p>
                <p><strong>Name:</strong> {Name}</p>
                <p><strong>Webpage:</strong> {Webpage}</p>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table >
  );
};

export default ServiceRequestItem3;
