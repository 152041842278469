import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../component/css/Forgotpassword.css';

const ForgotPassword = () => {
  const [Email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setPopupMessage('Verifying OTP...');
    setShowPopup(true);

    try {
      const response = await axios.post('/email_verification_via_otp.php', { Email });

      if (response.data.otp) {
        sessionStorage.setItem('otp', response.data.otp);
        setPopupMessage('OTP sent successfully! Please check your email.');
        setTimeout(() => {
          setShowPopup(false);
          navigate('/otp-verification');
        }, 2000);
      } else {
        setLoading(false);
        setPopupMessage('Please provide a valid EMAIL ADDRESS.');
      }
    } catch (error) {
      setLoading(false);
      setPopupMessage('There was an error! Please try again.');
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setLoading(false);
  };

  return (
    <div className="login_wall">
      <div className="Div_logo">
        <img src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Logo.png" alt="Logo" />
      </div>
      <div className="Fixed_button">
        <a href="#">Contact us</a>
      </div>
      <div className="Login_Box">
        <h2 className='Reset'>Reset your password</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <br />
            <br />
            <input
              type="email"
              id="email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Enter your email to get reset code'
              required
              disabled={loading}
            />
          </div>
          <br />
          <button className='reset-button' type="submit" disabled={loading}>
            {loading ? 'Processing...' : 'Reset Password'}
          </button>
        </form>
      </div>

      {/* Popup Component */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h6>{popupMessage}</h6>
            {!loading && <button onClick={closePopup}>Close</button>}
          </div>
        </div>
      )}

    </div>
  );
};

export default ForgotPassword;
