import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import axios from "axios";


const PopupComponent = (props) => {
  const [showButtons, setShowButtons] = useState(true);
  const [rowCount, setRowCount] = useState(1);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [machineID, setMachineID] = useState("");
  const [status, setStatus] = useState("");
  const [machinenamedata, setMachinenamedata] = useState([]);
  const [Servicerequestformdata, setContactnamedata] = useState({});
  const [isVisible, setIsVisible] = useState("Web");
  const [formData, setFormData] = useState([{ partNo: "", qty: "", description: "" }]);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [origin, setOrigin] = useState("");
  const [submittedMessage, setSubmittedMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);



  const email = sessionStorage.getItem('email');

  const [errors, setErrors] = useState({
    partNo: [],
    qty: [],
    description: [],
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      partNo: [],
      qty: [],
      description: [],
    };

    // Check if all required fields are filled
    if (!selectedMachine) {
      isValid = false;
    }

    if (!type) {
      isValid = false;
    }

    if (!description) {
      isValid = false;
    }

    if (type === "Part(s) Exchange" || type === "Part(s) / Consumable Request") {
      formData.forEach((part, index) => {
        if (!part.partNo) {
          isValid = false;
          newErrors.partNo[index] = "Part No. is required";
        }
        if (!part.qty) {
          isValid = false;
          newErrors.qty[index] = "Qty is required";
        }
        if (!part.description) {
          isValid = false;
          newErrors.description[index] = "Description is required";
        }
      });
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleAddRow = () => {
    setFormData([...formData, { partNo: '', qty: '', description: '' }]);
    setRowCount(rowCount + 1);
  };

  const handleChange = (index, field, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = value;
    setFormData(updatedFormData);

    const updatedErrors = { ...errors };
    if (field === "partNo" && value) updatedErrors.partNo[index] = "";
    if (field === "qty" && value > 0) updatedErrors.qty[index] = "";
    if (field === "description" && value) updatedErrors.description[index] = "";
    setErrors(updatedErrors);
  };

  const handleContactsChange = (selectedOptions) => {
    setSelectedContacts(selectedOptions);
  };

  const handleFileInputChange = (e) => {

  };

  const handleSaveNewClick = () => {
    if (validateForm()) {
      setIsLoading(true); // Set loading state to true

      const partsRequested = (type === "Part(s) Exchange" || type === "Part(s) / Consumable Request")
        ? formData.map((part) => ({
          PartNo: part.partNo,
          PartDescription: part.description,
          Qty: part.qty
        }))
        : [];

      const jsonStructure = {
        MachineID: machineID,
        Status: status,
        Description: description,
        Type: type,
        Origin: "Web",
        Contacts: selectedContacts.map((contact) => contact.value),
        ServiceRequestOwner: machinenamedata.find(m => m.id === selectedMachine).Assigned_Engineer_id,
        PartRequested: partsRequested,
        Email: email,
      };

      axios
        .post("/service_request_create.php", jsonStructure)
        .then((response) => {
          console.log("Successfully posted data:", response.data);

          // Show submitted message
          setSubmittedMessage("Your request has been submitted successfully!");

          // Reset the form
          resetForm(); // This will reset all fields including dropdowns
          setDescription("");

          // Optionally, clear the message after a few seconds
          setTimeout(() => {
            setSubmittedMessage("");
          }, 2000); // Adjust the delay as needed (2000 milliseconds = 2 seconds)

        })
        .catch((error) => {
          console.error("Error posting data:", error);
        })
        .finally(() => {
          setIsLoading(false); // Reset loading state
        });
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const [machineData, contactData] = await Promise.all([
        axios.get(`/sr_creation_machine_list.php?Customer_Email=${email}`),
        axios.get(`/sr_creation_contact_list.php?Customer_Email=${email}`)
      ]);

      setMachinenamedata(machineData.data.data);
      setContactnamedata(contactData.data.data);

      const allContacts = contactData.data.data.Contacts.map((contact) => ({
        value: contact.id,
        label: `${contact["Contact Name"]} - ${contact.Email}`,
      }));
      setSelectedContacts(allContacts);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [email]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (selectedMachine) {
      const machine = machinenamedata.find(m => m.id === selectedMachine);
      if (machine) {
        setSelectedOwner(machine.Assigned_Engineer);
      }
    }
  }, [selectedMachine, machinenamedata]);


  const submit_close = () => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    const partsRequested = (type === "Part(s) Exchange" || type === "Part(s) / Consumable Request")
      ? formData.map((part) => ({
        PartNo: part.partNo,
        PartDescription: part.description,
        Qty: part.qty
      }))
      : [];

    const jsonStructure = {
      MachineID: machineID,
      Status: status,
      Description: description,
      Type: type,
      Origin: "Web",
      Contacts: selectedContacts.map((contact) => contact.value),
      ServiceRequestOwner: machinenamedata.find(m => m.id === selectedMachine).Assigned_Engineer_id,
      PartRequested: partsRequested,
      Email: email,
    };

    axios
      .post("/service_request_create.php", jsonStructure)
      .then((response) => {
        console.log("Successfully posted data:", response.data);
        setSubmittedMessage("Your request has been submitted successfully!");
        resetForm();
        setDescription("");
        setTimeout(() => {
          setSubmittedMessage("");
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetForm = () => {
    setRowCount(1);
    setSelectedContacts([]);
    setSelectedOwner("");
    setType("");
    setDescription("");
    setMachineID("");
    setStatus("");
    setFormData([{ partNo: "", qty: "", description: "" }]);
    setSelectedMachine(null);
  };

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      display: "contents",
      width: "100%",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      display: "block",
      whiteSpace: "normal",
    }),
  };

  return (
    <div className={props.isPopupOpen ? "Popup_box" : "d-none"}>
      <div className="Inside_popup">
        <h1>Service Request Information</h1>
        <p>A brief description of the service you are requesting.</p>
        <div className="Section_">
          <div className="First_form">
            <div className="Selection_">
              <label>Machine Name</label>
              <select required
                name="Selectmachine"
                id="Selectmachine"
                onChange={(e) => {
                  const selectedMachineId = e.target.value;
                  setMachineID(selectedMachineId);
                  setSelectedMachine(selectedMachineId);
                }}
              >
                <option value="">Select machine</option>
                {machinenamedata.map((machine, index) => (
                  <option key={index} value={machine.id}>
                    {machine["Machine Name"]}
                  </option>
                ))}
              </select>
            </div>

            <div className="Selection_">
              <label>Type</label>
              <select
                name="Selecttype"
                id="Selecttype"
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">-None-</option>
                <option value="Part(s) Exchange">Part(s) Exchange</option>
                <option value="Part(s) / Consumable Request">Part(s) / Consumable(s) Order</option>
                <option value="2nd Level Remote Technical Support">2nd Level Remote Technical Support</option>
                <option value="On-Site Service Visit">On-Site Service Visit</option>
                <option value="Calibration Service">Calibration Service</option>
              </select>
            </div>

            <div className="Selection_" style={{ display: isVisible ? 'none' : 'none' }}>
              <label>Origin</label>
              <select
                name="Selectorigin"
                id="Selectorigin"
                className="origin-style"
                onChange={(e) => setOrigin(e.target.value)}
                value={origin}
              >
                <option value="">Select origin</option>
                <option value="Web">Web</option>
              </select>
            </div>

            <div style={{ display: 'none' }} className="Selection_">
              <label>Contacts</label>
              <div style={{ width: "435px" }}>
                <Select
                  isMulti
                  value={selectedContacts}
                  onChange={handleContactsChange}
                  options={Servicerequestformdata.Contacts && Servicerequestformdata.Contacts.map(
                    (contact) => ({
                      value: contact.id,
                      label: `${contact["Contact Name"]} - ${contact.Email}`,
                    })
                  )}
                  styles={customStyles}
                />
              </div>
            </div>

            <div className="Selection_">
              <label>Description</label>
              <input
                className="placeholder-description"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="  Description"
              />
            </div>
          </div>

          <div className="First_form">
            <div className="Selection_">
              <label>Service Request Owner</label>
              <div style={{ width: "435px", marginTop: '10px' }}>
                <input
                  type="text"
                  value={selectedOwner}
                  readOnly
                />
              </div>
            </div>

            <div style={{ marginTop: '15px' }} className="Selection_">
              <label>Diagnostic/Other Files</label>
              <input type="file" id="myfile" name="myfile" onChange={handleFileInputChange} />
            </div>
          </div>
        </div>

        {(type === "Part(s) Exchange" || type === "Part(s) / Consumable Request") && (
          <div>
            <h1>Part Requested</h1>
            {[...Array(rowCount)].map((_, index) => (
              <div key={index} className="First_forms">
                <div className="Selection_s">
                  <label>Part No.</label>
                  <input
                    type="text"
                    placeholder="Enter Part Number"
                    value={formData[index].partNo}
                    onChange={(e) => handleChange(index, "partNo", e.target.value)}
                    className={errors.partNo[index] ? 'error' : ''}
                  />
                  {errors.partNo[index] && <span className="error-message">{errors.partNo[index]}</span>}
                </div>
                <div className="Selection_s">
                  <label>Qty</label>
                  <input
                    type="number"
                    placeholder="Enter Qty in Numbers"
                    value={formData[index].qty}
                    onChange={(e) => handleChange(index, "qty", e.target.value)}
                    className={errors.qty[index] ? 'error' : ''}
                  />
                  {errors.qty[index] && <span className="error-message">{errors.qty[index]}</span>}
                </div>
                <div className="Selection_s">
                  <label>Description</label>
                  <input
                    type="text"
                    placeholder="Enter Text Description"
                    value={formData[index].description}
                    onChange={(e) => handleChange(index, "description", e.target.value)}
                    className={errors.description[index] ? 'error' : ''}
                  />
                  {errors.description[index] && <span className="error-message">{errors.description[index]}</span>}
                </div>
              </div>
            ))}
            <button className="Create-Service-Request" onClick={handleAddRow}>
              + Add Row
            </button>
          </div>
        )}

        {showButtons && (
          <div className="Submit_button_box">
            <button className="Cancel" onClick={props.onClose}>
              Cancel
            </button>
            <button className="Save_New" onClick={handleSaveNewClick}>
              Save and New
            </button>
            <button className="Save" onClick={submit_close}>
              Save
            </button>
          </div>
        )}
        {isLoading ? (
          <div className="popup-overlay">
            <div className="loading-message">Processing...</div>
          </div>

        ) : (
          submittedMessage &&
          <div className="popup-overlay">
            <div className="submitted-message">{submittedMessage}</div>
          </div>

        )}
      </div>
    </div>
  );
};

export default PopupComponent;
