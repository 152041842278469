import TopHeader from "../component/TopHeader";
import Secondheader from "../component/Second_header";
import React, { useState, useEffect } from "react";
import axios from "axios";
import '../component/css/Profilepersonal.css';

const Profilepersonal = () => {
  const [activeSection, setActiveSection] = useState("PersonalInformation");
  const [name, setName] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [preferences, setPreferences] = useState({
    language: '',
    timeFormat: '',
    timeZone: '',
    country: '',
    grouping: '',
    decimal: ''
  });
  const [isPreferencesAvailable, setIsPreferencesAvailable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPersonal, setLoadingPersonal] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [preferencesEmpty, setPreferencesEmpty] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const fetchPersonalData = async () => {
    try {
      const response = await axios.get('/get_user.php', {
        params: { email: sessionStorage.getItem("email") }
      });
      if (response.data) {
        const { name, phone, email } = response.data;
        setName(name || '');
        setPhoneNumber(phone || '');
        setEmail(email || '');
      }
    } catch (error) {
      console.error("There was an error fetching the personal data:", error);
    }
  };

  const fetchPreferencesData = async () => {
    try {
      const response = await axios.get('/get_user_profile.php', {
        params: { email: sessionStorage.getItem("email") }
      });

      if (response.data.message === "User profile not found") {
        setPreferencesEmpty(true);
        setIsPreferencesAvailable(false);
        return;
      }

      if (response.data) {
        const {
          Preferred_Language,
          Preferred_Time_Zone,
          Preferrred_Time_Format,
          Preferred_Country,
          Preferred_Grouping,
          Preferred_Decimal
        } = response.data;

        setPreferences({
          language: Preferred_Language || '',
          timeFormat: Preferrred_Time_Format || '',
          timeZone: Preferred_Time_Zone || '',
          country: Preferred_Country || '',
          grouping: Preferred_Grouping || '',
          decimal: Preferred_Decimal || ''
        });

        const isEmpty = Object.values(response.data).every(value => !value);
        setPreferencesEmpty(isEmpty);
        setIsPreferencesAvailable(!isEmpty);
      }
    } catch (error) {
      console.error("There was an error fetching the user preferences data:", error);
      setPreferencesEmpty(true);
      setIsPreferencesAvailable(false);
    }
  };

  useEffect(() => {
    if (activeSection === "PersonalInformation") {
      setLoadingPersonal(true);
      const timer = setTimeout(async () => {
        await fetchPersonalData();
        setLoadingPersonal(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [activeSection]);

  useEffect(() => {
    if (activeSection === "UserPreferences") {
      setLoading(true);
      const timer = setTimeout(async () => {
        await fetchPreferencesData();
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [activeSection]);

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();

    const data = {
      email,
      oldPassword,
      newPassword
    };

    try {
      await axios.post("/post_password.php", data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log("Password changed successfully");
    } catch (error) {
      console.error("There was an error changing the password:", error);
    }

    setOldPassword('');
    setNewPassword('');
    event.target.reset();
  };

  const handlePreferencesSubmit = async (event) => {
    event.preventDefault();

    const preferencesData = {
      email,
      language: event.target.language.value,
      timeFormat: event.target.timeFormat.value,
      timeZone: event.target.timeZone.value,
      country: event.target.country.value,
      grouping: event.target.grouping.value,
      decimal: event.target.decimal.value
    };

    try {
      if (preferencesEmpty) {
        await axios.post('/post_user_profile.php', preferencesData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setSuccessMessage("User  preferences created successfully");
      } else {
        await axios.put('/put_user_profile.php', preferencesData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setSuccessMessage("Data updated successfully"); // Set the success message here
      }
      await fetchPreferencesData();
      setIsEditing(false);
    } catch (error) {
      console.error("There was an error saving/updating user preferences:", error);
      setSuccessMessage("An error occurred while updating preferences."); // Optional: set an error message
    }

    event.target.reset();
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 3000); // Popup will disappear after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <div className="CMAMachines_box">
      <TopHeader />
      <div className="secondHeader-fixed-style">
        <Secondheader />
      </div>
      {successMessage && (
        <div className="popup-overlay">
          <p className="submitted-message">{successMessage}</p>
        </div>
      )}
      <div className="Profilepersonal_box">
        <div className="Profilepersonal_Tab">
          <h1>
            <b>Profile</b> Settings
          </h1>
          <div className="Buttons">
            <a
              href="#"
              className={activeSection === "PersonalInformation" ? "ActiveBoxx" : ""}
              onClick={() => handleButtonClick("PersonalInformation")}
            >
              <img
                src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Profile.png"
                className="Profile_img"
              />
              Personal
            </a>
            <a
              href="#"
              className={activeSection === "UserPreferences" ? "ActiveBoxx" : ""}
              onClick={() => handleButtonClick("UserPreferences")}
            >
              <img
                src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Asses.png"
                className="Profile_img"
              />
              User preferences
            </a>
          </div>
        </div>
        <div className="Profilepersonal_Boxes">
          <div
            className={`Information ${activeSection === "PersonalInformation" ? "" : "d-none"}`}
          >
            <div className="PersonalInformation_In">
              <h1 className="personal_information">Personal Information</h1>
              <p>This includes your name, email address, password</p>
              {loadingPersonal ? (
                <div className="loader"></div>
              ) : (
                <form onSubmit={handlePasswordSubmit} className="Personal_details">
                  <div className="Selection_bar">
                    <div className="Selection_">
                      <label htmlFor="name">Your name</label>
                      <input
                        placeholder=""
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required readOnly
                      />
                    </div>
                    <div className="Selection_">
                      <label htmlFor="Email">Your email address</label>
                      <input
                        placeholder=""
                        type="text"
                        id="Email"
                        name="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required readOnly
                      />
                    </div>
                    <div className="Selection_">
                      <label htmlFor="number">Your Phone no.</label>
                      <input
                        placeholder=""
                        type="number"
                        id="number"
                        name="number"
                        value={phone}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required readOnly
                      />
                    </div>
                    <div className="Selection_"></div>
                    <div className="Selection_">
                      <span>CHANGE PASSWORD</span>
                      <label htmlFor="oldPassword">Old Password</label>
                      <input
                        placeholder="Enter Old Password"
                        type="password"
                        id="oldPassword"
                        name="oldPassword"
                        required
                      />
                    </div>
                    <div className="Selection_">
                      <span><br /></span>
                      <label htmlFor="newPassword">New Password</label>
                      <input
                        placeholder="Enter New Password"
                        type="password"
                        id="newPassword"
                        name="newPassword"
                        required
                      />
                    </div>
                  </div>
                  <div className="LAst_btn">
                    <input type="submit" value="Submit" />
                  </div>
                </form>
              )}
            </div>
          </div>

          <div
            className={`Information ${activeSection === "UserPreferences" ? "" : "d-none"}`}
          >
            <div className="PersonalInformation_In UserPreferences">
              <div className="preferences-container">
                <div>
                  <h1 className="personal_information">User Preferences</h1>
                  <p>This includes your user preferences</p>
                </div>
                <div>
                  {!isEditing ? (
                    <button className="edit-button-preferences" onClick={handleEditClick}>Edit Preferences</button>
                  ) : (
                    <button className="edit-button-preferences" onClick={() => setIsEditing(false)}>Cancel Edit</button>
                  )}
                </div>
              </div>
              {loading ? (
                <div className="loader"></div>
              ) : (
                <form onSubmit={handlePreferencesSubmit} className="Personal_details">
                  <div className="Selection_">
                    <label htmlFor="language">Preferred Language</label>
                    <select
                      name="language"
                      id="language"
                      value={preferences.language}
                      onChange={(e) => setPreferences(prev => ({ ...prev, language: e.target.value }))}
                      required
                      disabled={!isEditing}
                    >
                      <option value="">--None--</option>
                      <option value="English (United States)">English (United States)</option>
                      <option value="Spanish">Spanish</option>
                    </select>
                  </div>

                  <div className="Selection_">
                    <label htmlFor="timeFormat">Preferred Time Format</label>
                    <select
                      name="timeFormat"
                      id="timeFormat"
                      value={preferences.timeFormat}
                      onChange={(e) => setPreferences(prev => ({ ...prev, timeFormat: e.target.value }))}
                      required
                      disabled={!isEditing}
                    >
                      <option value="">--None--</option>
                      <option value="12Hours">12 Hours</option>
                      <option value="24Hours">24 Hours</option>
                    </select>
                  </div>
                  <div className="Selection_">
                    <label htmlFor="timeZone">Preferred Time Zone</label>
                    <select
                      name="timeZone"
                      id="timeZone"
                      value={preferences.timeZone}
                      onChange={(e) => setPreferences(prev => ({ ...prev, timeZone: e.target.value }))}
                      required
                      disabled={!isEditing}
                    >
                      <option value="">--None--</option>
                      <option value="(GMT-5:00) Eastern Standard Time">(GMT-5:00) Eastern Standard Time</option>
                      <option value="(GMT-8:00) Pacific Standard Time">(GMT-8:00) Pacific Standard Time</option>
                    </select>
                  </div>
                  <div className="Selection_">
                    <label htmlFor="country">Preferred Country</label>
                    <select
                      name="country"
                      id="country"
                      value={preferences.country}
                      onChange={(e) => setPreferences(prev => ({ ...prev, country: e.target.value }))}
                      required
                      disabled={!isEditing}
                    >
                      <option value="">--None--</option>
                      <option value="United States">United States</option>
                      <option value="United Kingdom">United Kingdom</option>
                    </select>
                  </div>
                  <span>PREFERRED NUMBER FORMAT</span>
                  <div className="Selection_">
                    <label htmlFor="grouping">Preferred Grouping</label>
                    <select
                      name="grouping"
                      id="grouping"
                      value={preferences.grouping}
                      onChange={(e) => setPreferences(prev => ({ ...prev, grouping: e.target.value }))}
                      required
                      disabled={!isEditing}
                    >
                      <option value="">--None--</option>
                      <option value="Standard">Standard</option>
                      <option value="Comma">Comma</option>
                    </select>
                  </div>
                  <div className="Selection_">
                    <label htmlFor="decimal">Preferred Decimal</label>
                    <select
                      name="decimal"
                      id="decimal"
                      value={preferences.decimal}
                      onChange={(e) => setPreferences(prev => ({ ...prev, decimal: e.target.value }))}
                      required
                      disabled={!isEditing}
                    >
                      <option value="">--None--</option>
                      <option value="Dot">Dot</option>
                      <option value="Comma">Comma</option>
                    </select>
                  </div>
                  {isEditing && (
                    <div className="LAst_btn">
                      <input type="submit" value="Update" />
                    </div>
                  )}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profilepersonal;
