import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('password', password);

    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    try {
      const response = await fetch('/user_auth.php', requestOptions);
      const result = await response.json();

      console.log('result', result);

      if (result.response === 'success') {
        setPopupMessage(result.message);
        setIsError(false);
        setShowPopup(true);
        sessionStorage.setItem('email', email);
        setTimeout(() => {
          setShowPopup(false);
          navigate('/user-Machines');
        }, 2000);
      } else {
        setPopupMessage('Login failed. Please check your email and password.');
        setIsError(true);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setPopupMessage('An error occurred. Please try again later.');
      setIsError(true);
      setShowPopup(true);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="login_wall">
      <div className="Div_logo">
        <img
          src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Logo.png"
          alt="Logo"
        />
      </div>
      <div className="Fixed_button">
        <a href="#">Contact us</a>
      </div>
      <div className="Login_Box">
        <h2>Login to your account</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email </label>
          <br />
          <input
            type="text"
            required
            id="email"
            placeholder="balamia@gmail.com"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <label htmlFor="password" className="Label_text">
            Password
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                navigate('/Forgot-Password');
              }}
            >
              Forgot Password ？
            </a>
          </label>
          <input
            type="password"
            required
            id="password"
            placeholder="Enter your password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <input type="submit" value="Login" />
        </form>
      </div>

      {showPopup && (
        <div className="popups">
          <div
            className="popup-content"
            style={{
              backgroundColor: '#ff4d4d',
              color: 'white',
            }}
          >
            <p>{popupMessage}</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
