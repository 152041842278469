import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';

const NewPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password === confirmPassword) {
      try {
        const email = sessionStorage.getItem('email'); // Retrieve the email from session storage

        if (!email) {
          throw new Error('Email not found in session. Please try resetting your password again.');
        }

        const response = await axios.post('/reset_password.php', {
          email,
          password,
        });

        if (response.data.success) {
          setMessage('Password changed successfully!');
          setPassword('');
          setConfirmPassword('');
        } else {
          setMessage('Failed to change password. Please try again.');
        }
      } catch (error) {
        console.error('Error:', error);
        setMessage('An error occurred. Please try again.');
      }
    } else {
      setMessage('Passwords do not match! Passwords must be the same.');
    }

    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  return (
    <div className="login_wall">
      <div className="Div_logo">
        <img
          src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Logo.png"
          alt="Logo"
        />
      </div>
      <div className="Fixed_button">
        <a href="#">Contact us</a>
      </div>
      <div className="Login_Box">
        <h2>Create New Password</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Enter New Password
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                style={{ position: 'absolute', right: 10, top: 27, cursor: 'pointer' }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </label>
          <label>
            Confirm New Password
            <div style={{ position: 'relative' }}>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <span
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                style={{ position: 'absolute', right: 10, top: 27, cursor: 'pointer' }}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </label>
          <button className='reset-button' type="submit">Submit</button>
        </form>
        {message && <p style={{ color: message.includes('successfully') ? 'green' : 'red' }}>{message}</p>}
      </div>
    </div>
  );
};

export default NewPassword;
