import React, { useEffect, useState, useRef } from "react";
import TopHeader from "../component/TopHeader";
import Secondheader from "../component/Second_header";
import ServiceRequestItem3 from "../component/ServiceRequestitem3";
import PopupComponent from "../component/PopupComponent";
import axios from "axios";
import '../component/css/ServiceRequest.css';

const ServiceRequest = () => {
  const [serviceRequestsData, setServiceRequestsData] = useState([]);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const dataFetchedRef = useRef(false); // Track if data is fetched

  const handleCreateServiceRequestClick = () => {
    if (!isPopupOpen) {
      setIsPopupOpen(true);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    fetchData();
  };

  const customerEmail = sessionStorage.getItem('email');

  useEffect(() => {
    if (!dataFetchedRef.current) {
      fetchData();
      dataFetchedRef.current = true;
    }
  }, []);

  const fetchData = async () => {
    const apiUrl = '/main_service_request_list.php';
    const params = { Customer_Email: customerEmail };

    try {
      const response = await axios.get(apiUrl, { params });
      const { data } = response;

      const totalRecords = data["Total Service Requests"];
      const totalPages = recordsPerPage === "all" ? 1 : Math.ceil(totalRecords / recordsPerPage);
      setTotalPages(totalPages);
      setServiceRequestsData(data["Service Requests"]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filter === "Machine Name") {
      let sortedData = [...serviceRequestsData];
      if (sortOrder === "A-Z") {
        sortedData.sort((a, b) => a["Machine Name"].localeCompare(b["Machine Name"]));
      } else if (sortOrder === "Z-A") {
        sortedData.sort((a, b) => b["Machine Name"].localeCompare(a["Machine Name"]));
      }
      setServiceRequestsData(sortedData);
    } else if (filter === "Service Request Number") {
      let sortedData = [...serviceRequestsData];
      if (sortOrder === "Oldest First") {
        sortedData.sort((a, b) => a["Serial No"] - b["Serial No"]);
      } else if (sortOrder === "Newest First") {
        sortedData.sort((a, b) => b["Serial No"] - a["Serial No"]);
      }
      setServiceRequestsData(sortedData);
    }
  }, [sortOrder, filter, serviceRequestsData]);

  useEffect(() => {
    const totalRecords = serviceRequestsData.length;
    const totalPages = recordsPerPage === "all" ? 1 : Math.ceil(totalRecords / recordsPerPage);
    setTotalPages(totalPages);
  }, [recordsPerPage, serviceRequestsData]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setSortOrder("");
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getCurrentPageData = () => {
    if (recordsPerPage === "all") {
      return serviceRequestsData;
    }
    const startIndex = (currentPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    return serviceRequestsData.slice(startIndex, endIndex);
  };

  return (
    <div className="CMAMachines_box">
      <TopHeader />
      <div className="secondHeader-fixed-style">
        <Secondheader />
      </div>
      <div className="Service_top">
        <div className="Group_a">
          <h1>
            <b>Service</b> Request
          </h1>
          <button
            className="Create-Service-Request"
            onClick={handleCreateServiceRequestClick}
          >
            + Create Service Request
          </button>
          {isPopupOpen && (
            <PopupComponent onClose={handleClosePopup} isPopupOpen={isPopupOpen} email={customerEmail} />
          )}
        </div>
        <div className="Group_a Groups_record">
          <div>
            <p className="TotalRecords">
              Total {serviceRequestsData.length} Requests
            </p>
          </div>

          {!isPopupOpen && (
            <div className="Selection_box">
              <select name="Filter" id="Filter" onChange={handleFilterChange}>
                <option value="">Sort by</option>
                <option value="Machine Name">Machine Name</option>
                <option value="Service Request Number">Service Request Number</option>
              </select>

              {filter === "Machine Name" && (
                <div className="nested-dropdown">
                  <select name="SortOrder" id="SortOrder" onChange={handleSortOrderChange}>
                    <option value="">Sort by</option>
                    <option value="A-Z">A-Z</option>
                    <option value="Z-A">Z-A</option>
                  </select>
                </div>
              )}

              {filter === "Service Request Number" && (
                <div className="nested-dropdown">
                  <select name="SortOrder" id="SortOrder" onChange={handleSortOrderChange}>
                    <option value="">Sort Order</option>
                    <option value="Oldest First">Oldest First</option>
                    <option value="Newest First">Newest First</option>
                  </select>
                </div>
              )}
            </div>
          )}

          {totalPages > 1 && (

            <div className="Pagination">
              <button
                onClick={() => handlePageChange("prev")}
                disabled={currentPage === 1}
              >
                &#11164;
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => handlePageChange("next")}
                disabled={currentPage === totalPages}
              >
                &#11166;
              </button>
            </div>
          )}

          <div style={{ padding: '10px' }}></div>
          <select
            name="recordsPerPage"
            id="recordsPerPage"
            value={recordsPerPage}
            onChange={handleRecordsPerPageChange}
          >
            <option value="all">Show All Records</option>
            <option value="10">10 Records per page</option>
            <option value="50">50 Records Per Page</option>
            <option value="100">100 Records Per Page</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="loader" style={{ marginTop: '15%' }}></div>
      ) : (
        <div className="Second_BOX_part  CMAMachines">
          <div className="Flex-Details">
            {getCurrentPageData().map((request, index) => (
              <ServiceRequestItem3
                key={index}
                srNo={request["Serial No"]}
                description={request["Description"]}
                machineName={request["Machine Name"]}
                type={request["Type"]}
                status={request["Status"]}
                Time={request["Time"]}
                Name={request["Engineer"]}
                Webpage={request["Orgin"]}
                Recordid={request["Record Id"]}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceRequest;
