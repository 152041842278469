import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EquipmentForm from "./equipmentform";
import '../component/css/Parts-inventory.css';

function PartsDetailsInventory(props) {
    const navigate = useNavigate();
    const handleDetailsClick = () => {
        navigate("/inventoryDetails", { state: { equipmentData: props } });
        window.parent.postMessage({ messageType: 'scrolltop', data: 0 }, '*');
    };

    console.log('parts Inventory Props', props)

    const [showInquiryModal, setShowInquiryModal] = useState(false);

    const handleInquireButtonClick = () => {
        setShowInquiryModal(true);
    };

    const handleCloseInquiryModal = () => {
        setShowInquiryModal(false);
    };

    return (
        <>
            <div className="equipment_table_card PartsInventory_box">
                <table className="equipment-table">
                    {/* <thead>
            <tr>
              <th>JAD ID</th>
              <th>Description</th>
              <th>Manufacturer ID</th>
              <th>Part Number</th>
              <th>Revision</th>
              <th>Actions</th>
            </tr>
          </thead> */}
                    <tbody style={{ height: '20px' }}>
                        <tr>
                            <td className="jadid-td" style={{}}><strong>{props.jad_id}</strong></td>
                            <td className="description-td">{props.data["Product_Description"]}</td>
                            <td className="id-td">{props.data['Manufacturer_Id']}</td>
                            <td className="partsnumber-td">{props.Parts_number}</td>
                            <td className="revision-td">{props.Revision}</td>
                            <td className='flex-buttons'>
                                {/* <button className="more-details-btn" onClick={handleDetailsClick}>MORE DETAILS</button> */}
                                <div></div>
                                <button className="inquire-btn" onClick={handleInquireButtonClick}>INQUIRE</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <EquipmentForm
                showInquiryModal={showInquiryModal}
                handleCloseInquiryModal={handleCloseInquiryModal}
                values={props}
                producttype="inventory"
            />
        </>
    );
}

export default PartsDetailsInventory;
