import React from "react";
import { Link } from "react-router-dom";
import '../component/css/ServiceRequestItem.css'

const ServiceRequestItem = ({ 
  srNo,description,type,status,recordid
 }) => (
  <table className="T-thiss">
    <tbody className="table-body">
      <tr>
        <td className="Dark">
          <Link to={`/ServiceNotesStatus/${recordid}`}>{srNo}</Link>
        </td>
        <td className="requestDescription">{description}</td>
        
        <td className="Part_exchange">
          <p className="sts-sts" >{status}</p>
        </td> 
        <td className="requestType">{type}</td>
      </tr>
    </tbody>  

  </table>
  
);

export default ServiceRequestItem;
